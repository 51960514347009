import "./styles/Home.css";
import GlobalStyles from './styles/GloabalStyles';
import {ThemeProvider} from 'styled-components';
import {Routes, Route} from 'react-router-dom';
import {light} from './styles/Themes'
import Navigation from './components/Navigation';
import Team from "./components/sections/Team";
import RoadMap from "./components/sections/RoadMap";
import Faq from "./components/sections/Faq";
import Home from "./components/sections/Home";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import About from "./components/sections/About";
import {Analytics} from '@vercel/analytics/react';
import React from "react";
import Phase2About from "./components/Phase2";


export default function Index() {
    return (
        <>
            <GlobalStyles/>
            <ThemeProvider theme={light}>
                <Navigation/>
                <Home/>
                <About/>
                <Phase2About/>
                <RoadMap/>
                <Team/>
                <Faq/>
                <Footer/>
                <ScrollToTop/>
            </ThemeProvider>
            <Analytics/>
        </>
    );
}
