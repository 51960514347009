import React from "react";
import Swal from 'sweetalert2';
import {
  ThirdwebNftMedia,
  useContract,
  useNFT,
  Web3Button,
} from "@thirdweb-dev/react";
import styled from "styled-components";

const NftBox = styled.div`
  text-align: center;

  .btnMint {
    background: #202020;
    color: #fff;
    border-radius: 8px;
  }
`;

const NftMedia = styled(ThirdwebNftMedia)`
  width: 100%;
  height: auto;
`;

const nftDropContractAddress = "0xFDfE12d2b1bB7c9D087691264f903F71a5178C1c";
const stakingContractAddress = "0x6Fa675f9204fEeE9eeC24E63466CC403F96EaB53";

const ImageContainer = styled.div`
  width: auto;
  align-items: center;
  background-color:${props => props.theme.carouselColor};
  border:1px solid ${props => props.theme.text};
  padding:1rem;

border-radius: 20px;
cursor:pointer;

img{
  width:auto;
  height:auto;
  transition: all 0.3s ease;
}

  &:hover{
    img{
      transform:translateY(-0.5rem) scale(0.8);
    }
  }

  @media(max-width: 30em){
    width:auto;
  }

`

const NFTCard = ({ tokenId }) => {
  const { contract } = useContract(nftDropContractAddress,"nft-drop" );
  const { data: nft } = useNFT(contract, tokenId);

  return (
    <>
      <ImageContainer>
      {nft && (
        <NftBox>
          {nft.metadata && <NftMedia metadata={nft.metadata} />}
          <h3>{nft.metadata.name}</h3>
          <Web3Button
            className="btnMint"
            action={(contract) =>
              contract?.call("withdraw", [[nft.metadata.id]])
            }
            contractAddress={stakingContractAddress}

            onSuccess={() => {
              Swal.fire({
                title: 'NFT Withdraw!',
                text: 'Your NFT has been successfully Withdraw.',
                icon: 'success',
                confirmButtonText: 'OK',
              });
            }}

            onError={(error) => {
              Swal.fire({
                title: 'Error',
                text: `An error occurred, Please try again`,
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  popup: 'smaller-sweet-alert', // Add your custom size class here
                },
              });
            }}
          >
            Withdraw
          </Web3Button>
        </NftBox>
      )}
      </ImageContainer>
    </>
  );
};

export default NFTCard;
