import React,{useRef,useLayoutEffect} from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Section = styled.section`
min-height: 100vh;
height:auto;
width:100vw;
background-color: ${props => props.theme.text};
position:relative;
color: ${(props) => props.theme.body};

display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
`

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props => props.theme.carouselColor)};
  width: fit-content;

  @media(max-width: 48em){
    font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width:75%;
margin: 2rem auto;

display:flex;
justify-content:space-between;
align-content:center;

@media(max-width: 64em){
  width:80%;
}
@media(max-width: 48em){
  width:90%;
  flex-direction:column;

  &>*:last-child{
    &>*:first-child{
    margin-top:0;
    }
  }
}
`
const Box = styled.div`
width:45%;

@media(max-width: 64em){
  width:90%;
  align-self:center;
}
`

const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  
  useLayoutEffect(() => {
    let element = ref.current;
    let scrollTriggerInstance = ScrollTrigger.create({
      trigger: element,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
      scrub: true,
      // markers: true,
    });
  
    return () => {
      scrollTriggerInstance.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>
      <Container>
      <Box>
         <Accordion title="WHERE CAN I VIEW MY NFTS?" >
             Your minted NFTs are accessible in the Stake section.
         </Accordion>

        <Accordion title="HOW CAN I USE MY NFT?" >
          You will be able to use your NFT by utilizing our staking functionality to earn a consistent 3% monthly return, turning your NFT into a source of passive income.
        </Accordion>

          <Accordion title="HOW TO STAKE MY NFT?" >
              Visit the Stake section in the navbar, then click "Stake" on your chosen NFT.
          </Accordion>
      </Box>
      <Box>
          <Accordion title="WHAT IS PHASE 10?" >
              Phase 10 is an exclusive collection of NFTs, each represented as ERC-721 tokens on the Polygon blockchain. With over 200 meticulously hand-drawn traits, every NFT is unique. As a Phase 10 holder, you become part of a dynamic community with various benefits.
          </Accordion>
          <Accordion title="HOW OUR BOT WORKS?" >
              Our bot operates in the forex market daily, generating consistent monthly returns of 3% through strategic trading activities.
          </Accordion>
          <Accordion title="WHERE TO SELL MY NFT" >
              Currently, selling your NFT is not available. Stay tuned for our upcoming marketplace. In the meantime, enjoy your 3% ROI.
          </Accordion>
      </Box>
     
      </Container>
    </Section>
  )
}

export default Faq