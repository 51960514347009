import React from 'react'
import styled, { ThemeProvider } from 'styled-components';
import Carousel from '../Carousel';
import Button from '../Button';
import {dark} from '../../styles/Themes' 

const Section = styled.section`
min-height:100vh;
width:100%;
background-color: ${props=> props.theme.text};


display:flex;
justify-content: center;
align-items: center;
position: relative;
`

const Container = styled.div`
width:75%;
margin: 0 auto;
// background-color:lightblue;

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 70em){
  width:85%;
}

@media (max-width: 64em){
  width:100%;
  flex-direction:column;

  &>*:last-child{
    width:80%;
  }
}

@media (max-width: 40em){
  
  &>*:last-child{
    width:90%;
  }
}

`

const Box = styled.div`
width: 50%;
height:100%;
min-height:60vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-heigh:50vh;
}

`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em){
    width:100%;
    text-align:center;
  }

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em){
    font-size: ${(props) => props.theme.fontlg};
  }
`

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight:400;

  @media (max-width: 64em){
    width:100%;
    text-align:center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em){
    font-size: ${(props) => props.theme.fontsm};
  }
`

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  width: 80%;
  align-self: flex-start;
  margin: 1rem auto;
  font-weight:400;

  @media (max-width: 64em){
    width:100%;
    text-align:center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em){
    font-size: ${(props) => props.theme.fontxs};
  }
`

const ButtonContainer = styled.div`
width: 80%;
margin: 1rem auto;
display:flex;
align-self: flex-start;

@media (max-width: 64em){
  width:100%;
  button{
    margin:0 auto;
  }
}
`

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box> <Carousel /> </Box>
        <Box>
          <Title>
            Welcome To The <br/> Phase 1 Staking Club.
          </Title>
          <SubText>
            The Phase10 CLUB is an exclusive collection of NFTs—distinctive digital collectibles. These unique assets, represented as ERC-721 tokens on the Polygon blockchain and hosted on IPFS, offer more than just aesthetics.
          </SubText>
          <SubTextLight>
            What sets us apart is our innovative staking contract that ensures a 3% return every month, providing you with a consistent source of income as a Phase 1 holder. Elevate your investment experience with Phase10—where uniqueness meets profitability through our staking contract.
          </SubTextLight>
          <ButtonContainer>
          <ThemeProvider theme={dark}>
            <Button text="STAKE NOW" link='/stake' />
          </ThemeProvider>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  )
}

export default About