import React from 'react'
import GIF from '../assets/Home Video.mp4'
import GIF2 from '../assets/videoHome3.mp4'
import styled from 'styled-components'

const VideoContainer = styled.div`
width: 70%;
height: auto;

video{
    width: 100%;
    height: auto;
}

@media (max-width:64em){
  min-width:40vh;
}

`


const CoverVideo = () => {
  return (
    <VideoContainer>
        <video src={GIF2} type="video/mp4"  autoPlay muted loop playsInline controls={false} />
    </VideoContainer>
  )
}

export default CoverVideo