import React, { useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'; 
import DrawSvg from '../DrawSvg';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import gsap from "gsap";

const Section = styled.section`
min-height: 100vh;
width:100vw;
background-color: ${props => props.theme.body};
position:relative;
`

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display:flex;
  justify-content:center;
  align-items:center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props => props.theme.text)};
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
width:70%;
height:200vh;
background-color: ${(props => props.theme.body)};
margin: 0 auto;
display:flex;
justify-content:center;
align-items:center;
position:relative;

@media (min-width: 1025px) and (max-width: 1535px) {
  width: 80%;
}

@media (min-width: 1060) and (max-width: 769) {
  width: 70%;
}

@media (max-width: 64em){
  width: 100%;
}
@media (max-width: 48em){
  width: 100%;
}
`

const SvgContainer = styled.div`
display: flex;
justify-content:center;
align-items:center
`
const Items = styled.ul`
list-style:none;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
// background-color:lightblue;

@media (max-width: 48em){
  width: 90%;
}

&>*:nth-of-type(2n + 1){
  justify-content: start;

  @media (max-width: 48em){
    justify-content: center;
  }

  div{
    border-radius:50px 0 50px 0;
    text-align:right;

    @media (max-width: 48em){
      border-radius:0 50px 0 50px;
    text-align:left;
      p{
        border-radius: 0 40px 0 40px;
      }
    }

  }
  p{
    border-radius:40px 0 40px 0;
  }
}

& > *:nth-of-type(2n){
  justify-content: end;

  @media (max-width: 48em){
    justify-content: center;
  }
  div{
    border-radius:0 50px 0 50px;
    text-align:left;
  }
  p{
    border-radius: 0 40px 0 40px;
  }
}

`
const Item = styled.li`
width:100%;
height:100%;
display:flex;

@media (max-width: 48em){
  justify-content:flex-end !important;
}
`
const ItemContainer = styled.div`
width:40%;
height: fit-content;
padding: 1rem;
border: 3px solid ${props => props.theme.text};



@media (max-width: 48em){
  width:100%;
}
`

const Box = styled.p`
height: fit-content;
background-color:${props => props.theme.carouselColor};
color:${props => props.theme.text};
padding:1rem;
position:relative;
border:1px solid ${props => props.theme.text};
`
const SubTitle = styled.span`
display:block;
font-size: 1.7em;
text-transform:capitalize;
color:${props => props.theme.text};



@media (min-width: 1025px) and (max-width: 1435px) {
  font-size: 1em;
  font-weight:600;
}

@media (min-width: 1060) and (max-width: 769) {
  font-size: 1em;
  font-weight:600;
}

@media (max-width: 64em){
  font-size: 1em;
  font-weight:600;
}

@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontlg};
  font-weight:600;
}
`;

const Text = styled.span`
display:block;
font-size: ${props => props.theme.fontsm};
text-transform:capitalize;
color:${props => props.theme.text};

font-weight:400;
margin: 0.5rem 0;

@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxs};
}
`;

const RoadMapItem = ({title, subtext, addToRefs}) => {
  return(
    <Item ref={addToRefs}>
      <ItemContainer>
        <Box>
          <SubTitle>{title}</SubTitle>
          <Text>{subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  )
}

const RoadMap = () => {

  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollToPlugin)

  const addToRefs = (el) => {
    if(el && !revealRefs.current.includes(el)){
      revealRefs.current.push(el);
    }
  }

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach( (el,index) =>{
      t1.fromTo(
        el.childNodes[0],
        {
          y:"0",
        },
        {
          y:"-30%",

          scrollTrigger:{
            id: `section-${index +1}`,
            trigger:el,
            start:'top center+=200px',
            end:'bottom center',
            scrub:true,
            //markers:true,
          }
        }
      )
      

    } )
  
    return () => {
      if(t1) t1.kill();
    };
  }, [])

  return (
    <Section id="roadmap">
      <Title>RoadMap</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem addToRefs={addToRefs} title="Step 1: NFT Mint" subtext="Launch a user-friendly web platform allowing users to mint their unique NFTs seamlessly. This marks the initiation of our decentralized ecosystem."/>
          <RoadMapItem addToRefs={addToRefs} title="Step 2: NFT Staking" subtext="Introduce an innovative bot system enabling NFT holders to stake their assets. The bot will engage in forex trading, generating consistent income to provide stakers with a lucrative 3% monthly return on their investments."/>
          <RoadMapItem addToRefs={addToRefs} title="Step 3: RoboBot for NFT Holders" subtext="Introducing Robot, offering up to 20% monthly ROI. Secure exclusive bot access by acquiring our $500 NFT. Also generate passive income through referrals with our affiliate program."/>
          <RoadMapItem addToRefs={addToRefs} title="Step 4: Strategic Partnerships with Trading Giants" subtext="Form key partnerships with major players in the trading realm to turbocharge our bot setups."/>
          <RoadMapItem addToRefs={addToRefs} title="Step 5: NFT Marketplace Integration" subtext="Seamlessly integrate a robust NFT marketplace into the platform, offering users a secure and efficient environment to buy, sell, and trade their NFTs. This marketplace will enhance liquidity and foster a vibrant community."/>
          <RoadMapItem addToRefs={addToRefs} title="More Steps Coming Soon.." subtext=""/>
        </Items>
      </Container>
    </Section>
  )
}

export default RoadMap