import React from 'react'
import styled, { ThemeProvider } from 'styled-components';
import Button from './Button';
import {dark} from '../styles/Themes'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '@fortawesome/fontawesome-free/css/all.min.css';

import img  from "../assets/gold.png";
const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.76);
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainTitle = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: #C8AC5A;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
  border-bottom: 3px solid rgba(0, 0, 0, 0.87);
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
`;

const Container = styled.div`
  width:75%;
  margin: 0 auto;
  // background-color:lightblue;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em){
    width:85%;
  }

  @media (max-width: 64em){
    width:100%;
    flex-direction:column;

    &>*:last-child{
      width:80%;
    }
  }

  @media (max-width: 40em){

    &>*:last-child{
      width:90%;
    }
  }

`

const Box = styled.div`
  width: 50%;
  height:100%;
  min-height:60vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em){
    min-heigh:50vh;
  }

`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: #C8AC5A;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  border-bottom: 3px solid rgba(0, 0, 0, 0.87);
  @media (max-width: 64em){
    width:100%;
    text-align:center;
  }

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em){
    font-size: ${(props) => props.theme.fontlg};
  }
`

const SubText = styled.p`
  font-size: 1.2em;
  color: #C8AC5A;
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  width: 80%;
  align-self: flex-start;
  margin: 1rem auto;
  font-weight:400;

  @media (max-width: 64em){
    width:100%;
    text-align:center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em){
    font-size: ${(props) => props.theme.fontxs};
  }
`

const ButtonContainer = styled.div`
  width: 80%;
  margin: 1rem auto;
  display:flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  button:first-child {
    margin-right: 1rem;
  }
  @media (max-width: 64em){
    width:100%;
    button{
      margin:0 auto;
    }
  }
`
const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #838026; /* Arrow color */
  margin: 0 1rem; /* Adjust margin as needed */
`;

const Phase2About = () => {
    return (

        <Section id="phase2">
            <Wrapper>
                <MainTitle>Phase 2</MainTitle>
                <Container>

                    <Box>
                        <Title>
                            Welcome to the next chapter of your Phase10 journey - <b>Phase 2!</b>
                        </Title>
                        <SubText>
                            Introducing RoboBot a groundbreaking system delivering up to 20% monthly return on investments. Get on board by Minting our $500 NFT, providing exclusive access to leverage our advanced bot via RoboForex copy trading.
                        </SubText>
                        <SubText> Moreover, take advantage of our $500 NFTs, which come with an 20% affiliate commission program facilitated by our partners, Reminto. This empowers you to not only mint the NFT but also generate a referral link, allowing you to earn passive income by referring others to our innovative project.</SubText>
                        <ButtonContainer>
                            <ThemeProvider theme={dark} >
                                <Button text="Mint Phase 2 NFT" link='https://market.reminto.com/explore/phase-2' style={{ color: '#838026' }}/>
                                <i className="fas fa-arrow-right" style={{ fontSize: '27px', marginLeft: '1rem', marginRight: '1.8rem', marginTop:'0.7rem' }}></i>
                                <Button text="Join RoboBot Now!" link='https://www.copyfx.com/ratings/rating-all/show/330823/tab/profit/period/week/'/>
                            </ThemeProvider>
                        </ButtonContainer>
                    </Box>
                    <Box> <img src={img} alt="Gold" />  </Box>
                </Container>
            </Wrapper>
        </Section>
    )
}

export default Phase2About