import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { Web3Button } from "@thirdweb-dev/react";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";


const nftDropContractAddress = "0xFDfE12d2b1bB7c9D087691264f903F71a5178C1c"

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;

  span {
    text-transform: uppercase;
    font-family: "Akaya Telivigala, cursive";
  }

  .text-1 {
    color: blue;
  }

  .text-2 {
    color: orange;
  }

  .text-3 {
    color: red;
  }

  @media (max-width:70em){
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width:48em){
    align-self:center;
    text-align:center;
  }
  @media (max-width:40em){
    width:90%;
  }

`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba},0.6)`};
  font-weight:600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width:40em){
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width:48em){
    align-self:center;
    text-align:center;
  }
`;

const ButtonContainer = styled.div`
width: 80%;
align-self: flex-start;

.btnMint {
  background: #202020;
  color: #fff;
  border-radius: 20px;
}

@media (max-width:48em){
  align-self:center;
  text-align:center;

  button{
    margin: 0 auto;
  }
}
`



const TypeWriterText = () => {
    const navigate = useNavigate();
  return (
    <>
      <Title>
        Discover a new era of cool
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString('<span class="text-1"> NFTs. </span>')
              .pauseFor(2000)
              .deleteAll()
              .typeString('<span class="text-2"> Collectible Items. </span>')
              .deleteAll()
              .typeString('<span class="text-3"> Rappers! </span>')
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </Title>
      <SubTitle>Bored of common NFTs? Mint Phase 1</SubTitle>
      <SubTitle>Mint Price | 100 USDT</SubTitle>


        <ButtonContainer>
            <Web3Button
                className="btnMint"
                contractAddress={nftDropContractAddress}
                action={(contract) => contract.erc721.claim(1)}
                onSuccess={() => {
                    Swal.fire({
                        title: 'NFT Claimed!',
                        text: 'Your NFT has been successfully claimed.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        // Navigate to localhost/stake after successful mint
                        navigate('/stake');
                    });
                }}
                onError={(error) => {
                    Swal.fire({
                        title: 'Error',
                        text: 'An error occurred. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                            popup: 'smaller-sweet-alert',
                        },
                    });
                }}
            >
                Mint Now
            </Web3Button>
        </ButtonContainer>
      
    </>
  );
};

export default TypeWriterText;
