import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

const Btn = styled.button`
display: inline-block;
background-color: ${props => props.theme.text};
color:  #C8AC5A;
outline: none;
border:none;

font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;


&:hover{
    transform:scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%) scale(0);
    border:2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;

}


&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem
}

`
const Button = ({ text, link }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
        if (link.startsWith('http://') || link.startsWith('https://')) {
            // Open the external link in a new tab/window
            window.open(link, '_blank');
        } else {
            // Use navigate to go to the specified route for internal links
            navigate(link);
        }
    };
  
    return (
      <Btn onClick={handleClick}>
        {text}
      </Btn>
    );
  };
  
  export default Button;