import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import {
  ConnectWallet,
  ThirdwebNftMedia as OriginalThirdwebNftMedia,
  useAddress,
  useContract,
  useContractRead,
  useOwnedNFTs,
  useTokenBalance,
  Web3Button,
} from "@thirdweb-dev/react";
import { BigNumber, ethers } from "ethers";
import styled from "styled-components";

import NFTCard from "../components/NFTCard";
import Logo from "../components/Logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #EEEDDE;
  color: #202020;
  font-family: 'Sora', sans-serif;

  .btnMint {
    background: #202020;
    color: #fff;
    border-radius: 20px;
  }
  
`;

const Heading1 = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 20px 0;
  border: 1px solid #131313;
`;

const TokenGrid = styled.div`
  display: flex;
  flex-direction: column; /* Change to column for mobile */
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 64em) {
    flex-direction: row; /* Change back to row for larger screens */
    justify-content: space-around;
  }
`;

const TokenItem = styled.div`
  text-align: center;
`;

const TokenLabel = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const TokenValue = styled.p`
  font-size: 1rem;
  color: #333;
`;

const NftBoxGrid = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
`;

const ThirdwebNftMedia = styled(OriginalThirdwebNftMedia)`
  width: 300%;
  max-width: 300px;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #EEEDDE;
  color: #202020;
  width: 85%;
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;

  .btnMint {
    background: #202020;
    color: #fff;
    border-radius: 20px;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;

const BoldText = styled.span`
  font-weight: 700;
`;

// Styled component for the loading message
const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  margin-top: 50px;
`;

const nftDropContractAddress = "0xFDfE12d2b1bB7c9D087691264f903F71a5178C1c";
const tokenContractAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
const stakingContractAddress = "0x6Fa675f9204fEeE9eeC24E63466CC403F96EaB53";

const Stake = () => {
  const address = useAddress();
  const { contract: nftDropContract } = useContract(
    nftDropContractAddress,
    "nft-drop"
  );
  const { contract: tokenContract } = useContract(
    tokenContractAddress,
    "token"
  );
  const { contract, isLoading } = useContract(stakingContractAddress);
  const { data: ownedNfts } = useOwnedNFTs(nftDropContract, address);
  const { data: tokenBalance } = useTokenBalance(tokenContract, address);
  const [claimableRewards, setClaimableRewards] = useState();
  const { data: stakedTokens } = useContractRead(contract, "getStakeInfo", [
    address,
  ]);

  const [timeUntilClaim, setTimeUntilClaim] = useState(null);
  const [stakingStartDate, setStakingStartDate] = useState(null);

  useEffect(() => {
    if (!contract || !address) return;

    async function loadClaimableRewards() {
      const stakeInfo = await contract?.call("getStakeInfo", [address]);
      setClaimableRewards(stakeInfo[1]);
    }

    loadClaimableRewards();
  }, [address, contract]);

  async function stakeNft(id) {
    if (!address) return;

    const isApproved = await nftDropContract?.isApproved(
      address,
      stakingContractAddress
    );
    if (!isApproved) {
      await nftDropContract?.setApprovalForAll(stakingContractAddress, true);
    }
    await contract?.call("stake", [[id]]);
  }

    useEffect(() => {
        // Staking is live today
        const stakingStartDate = new Date();

        // Calculate the time until the next claim date (30th of March of this year)
        const nextClaimDate = new Date(Date.UTC(stakingStartDate.getUTCFullYear(), 5, 30));

        const timeDifference = nextClaimDate - stakingStartDate;

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));


      setTimeUntilClaim({ days, hours, minutes });
      setStakingStartDate(stakingStartDate)
      console.log(stakingStartDate.toDateString().slice(4))
    }, []);

  if (isLoading) {
    return (
      <Container>
        <LoadingMessage>Loading...</LoadingMessage>
      </Container>
    );
  }

  return (
    <>
      <NavBar>
        <Logo />
        <div className="desktop">
          <ConnectWallet  className="btnMint" />
        </div>
      </NavBar>
      <Container>
        <Heading1>Stake Now Your NFTs</Heading1>
        {/*<Divider />*/}

        {!address ? (
          <ConnectWallet className="btnMint" />
        ) : (
          <>
            <h2 style={{ borderBottom: '3px solid rgba(0, 0, 0, 0.87)' }}>MONTHLY ROI 3%</h2>

            <TokenGrid>
              <TokenItem>
                <TokenLabel>Claimable Rewards</TokenLabel>
                <TokenValue>
                  <b>
                    {!claimableRewards
                      ? "Loading..."
                      : ethers.utils.formatUnits(claimableRewards, 6)}
                  </b>{" "}
                  {tokenBalance?.symbol}
                </TokenValue>
              </TokenItem>
              <TokenItem>
                <TokenLabel>Current Balance</TokenLabel>
                <TokenValue>
                  <b>{tokenBalance?.displayValue}</b> {tokenBalance?.symbol}
                </TokenValue>
              </TokenItem>
            </TokenGrid>

              {timeUntilClaim && (
                  <p>
                      {(stakingStartDate.toDateString().slice(4) === 'Jun 30 2024' ||
                          stakingStartDate.toDateString().slice(4) === 'Jun 31 2024' ||
                          stakingStartDate.toDateString().slice(4) === 'Jul 01 2024')  ? (
                          <>
                            Claim Now Your Rewards!  <br/> <br/> Next lock-in: <BoldText>September 1st, 2024.</BoldText>
                          </>
                      ) : (
                          <>

                              You can claim your rewards in{' '}
                              <BoldText>
                                  {timeUntilClaim.days} days {timeUntilClaim.hours} hours{' '}
                                  {timeUntilClaim.minutes} minutes
                              </BoldText>
                              . The next claim date is on the <BoldText> 30th of{' '}
                               June</BoldText>.
                          </>
                      )}
                  </p>
              )}

            <Web3Button
                className="btnMint"
                action={async (contract) => {
                  const currentDate = new Date().toDateString().slice(4);
                  // console.log(currentDate)

                  if (
                      currentDate !== 'Jun 30 2024' &&
                      currentDate !== 'Jun 31 2024' &&
                      currentDate !== 'Jul 01 2024'
                  ) {
                    Swal.fire({
                      title: 'Error',
                      text: 'Rewards are locked. Please try again on June 30, 2024.',
                      icon: 'error',
                      confirmButtonText: 'OK',
                      customClass: {
                        popup: 'smaller-sweet-alert',
                      },
                    });
                  } else if (claimableRewards && parseFloat(ethers.utils.formatUnits(claimableRewards, 6)) < 3) {
                    Swal.fire({
                      title: 'Error',
                      text: 'Claimable rewards must be at least 3 USDT. Please try again later.',
                      icon: 'error',
                      confirmButtonText: 'OK',
                      customClass: {
                        popup: 'smaller-sweet-alert',
                      },
                    });
                  } else {
                    // Proceed with the claimRewards action if claimableRewards is greater than or equal to 3 USDT
                    await contract.call("claimRewards").then(() => {
                      Swal.fire({
                        title: 'Rewards Claimed!',
                        text: 'Your Rewards has been successfully claimed.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                      });
                    }).catch((error) => {
                      Swal.fire({
                        title: 'Error',
                        text: 'An error occurred. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                          popup: 'smaller-sweet-alert',
                        },
                      });
                    });
                  }
                }}
                contractAddress={stakingContractAddress}
            >
              Claim Rewards
            </Web3Button>


            <Divider />
            <h2>Staked NFTs</h2>
            {<NftBoxGrid>
              {stakedTokens &&
                stakedTokens[0]?.map((stakedToken) => (
                  <NFTCard
                    tokenId={stakedToken.toNumber()}
                    key={stakedToken.toString()}
                  />
                ))}
            </NftBoxGrid>}

            <Divider />
            <h2>Your NFTs</h2>
            <NftBoxGrid>
              {ownedNfts?.map((nft) => (
                <div key={nft.metadata.id.toString()}>
                  <ThirdwebNftMedia metadata={nft.metadata} />
                  <h3>{nft.metadata.name}</h3>
                  <Web3Button
                    className="btnMint"
                    contractAddress={stakingContractAddress}
                    action={() => stakeNft(nft.metadata.id)}

                    onSuccess={() => {
                      Swal.fire({
                        title: 'Staked!',
                        text: 'Your NFT has been successfully Staked.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                      });
                    }}

                    onError={(error) => {
                      Swal.fire({
                        title: 'Error',
                        text: `An error occurred, Please try again`,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                          popup: 'smaller-sweet-alert', // Add your custom size class here
                        },
                      });
                    }}
                  >
                    Stake
                  </Web3Button>

                </div>
              ))}
            </NftBoxGrid>
          </>
        )}
      </Container>
    </>
  );
};

export default Stake;
